import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'

import { LocalStorage } from '@alliance/shared/storage'
import { isValidKey } from '@alliance/shared/utils'

import { CompanyVerificationStatus } from '../../models'

const VERIFICATION_BY_CAP_FEATURE_KEY = 'verificationByCapFeatureKey'
const VERIFICATION_STATUS_FEATURE_KEY = 'verificationStatusFeatureKey'

const isCompanyVerificationStatus = (value: unknown): value is CompanyVerificationStatus => {
  const obj: Record<CompanyVerificationStatus, null> = {
    checked: null,
    onInspection: null,
    deflected: null,
    registered: null
  }

  if (typeof value === 'string' && isValidKey(value, obj)) {
    return true
  }

  return false
}

@Injectable({ providedIn: 'root' })
export class CompanyVerificationFeatureService {
  public constructor(private readonly storage: LocalStorage) {}

  public isVerificationByCapEnabled$(): Observable<boolean> {
    if (this.storage.getItem(VERIFICATION_BY_CAP_FEATURE_KEY)) {
      return of(true)
    }

    return of(false)
  }

  public getVerificationStatus(): CompanyVerificationStatus | null {
    const status = this.storage.getItem(VERIFICATION_STATUS_FEATURE_KEY)?.trim()

    if (status && isCompanyVerificationStatus(status)) {
      return status
    }

    return null
  }
}
