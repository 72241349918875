import { LangCode } from '@alliance/jobseeker/domain'
import { TranslationLanguageEnum } from '@alliance/shared/domain-gql'

export const transformLangValue = (lang?: LangCode | null): TranslationLanguageEnum | null => {
  switch (lang) {
    case 'ua':
      return TranslationLanguageEnum.Ukrainian
    case 'ru':
      return TranslationLanguageEnum.Russian
    case 'en':
      return TranslationLanguageEnum.English
    default:
      return null
  }
}
