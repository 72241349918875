import { NotebookCompanyInfo, NotebookService } from '@alliance/employer/data-access'
import { AuthService } from '@alliance/shared/auth/api'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { distinctUntilChanged, map, share, shareReplay, switchMap } from 'rxjs/operators'
import { CompanyVerificationService } from '@alliance/employer/company/domain'

@Injectable({
  providedIn: 'root'
})
export class CompanyInfoService {
  public companyInfo$: Observable<NotebookCompanyInfo | null>
  public isCompanyVerified$: Observable<boolean>

  public constructor(private notebookService: NotebookService, private authService: AuthService, private companyVerificationService: CompanyVerificationService) {
    const isEmployer$ = this.authService.token$.pipe(
      distinctUntilChanged(),
      map(token => !!token && this.authService.isEmployer),
      share()
    )

    this.companyInfo$ = isEmployer$.pipe(
      switchMap(isEmployer => {
        if (!isEmployer) {
          return of(null)
        }
        return this.notebookService.companyInfoV2({
          notebookId: this.authService.user?.NotebookId || 0,
          userId: this.authService.user?.MultiUserId || 0
        })
      }),
      shareReplay(1)
    )

    this.isCompanyVerified$ = this.companyVerificationService.isVerified$
  }
}
