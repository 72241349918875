import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { debounceTime, from, fromEvent, map, mergeMap, Observable, share, startWith } from 'rxjs'

import { NgZoneHelperService } from './ng-zone-helper.service'

const EVENTS = ['scroll', 'click', 'mousemove']

const IDLE_USER_DEBOUNCE_TIME = 2 * 60 * 60 * 1000 // 2 hours

@Injectable({ providedIn: 'root' })
export class UserActivityService {
  private readonly eventsStream$ = from(EVENTS).pipe(
    mergeMap(event => fromEvent(this.document, event).pipe(this.ngZoneHelperService.outsideNgZone())),
    share(),
    map(() => void 0)
  )

  public constructor(@Inject(DOCUMENT) private document: Document, private readonly ngZoneHelperService: NgZoneHelperService) {}

  public idleUser$(debounce: number = IDLE_USER_DEBOUNCE_TIME): Observable<void> {
    return this.eventsStream$.pipe(startWith(void 0), debounceTime(debounce))
  }

  public get nextUserEvent$(): Observable<void> {
    return this.eventsStream$
  }
}
