import { DataLayerPushEvent } from '@alliance/shared/utils'

export type StatisticSelectRecommendationEventButton = 'edit_vacancy' | 'make_hot' | 'prozora' | 'select_service' | 'share_vacancy' | 'view_candidates'

export const getStatisticSelectRecommendationEvent = (type: string, button: StatisticSelectRecommendationEventButton): DataLayerPushEvent => ({
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: `Select_recommendation_${type}`,
  eventLabel: button,
  eventContent: null
})
