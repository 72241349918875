import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@alliance/shared/auth/api'
import { CompanyVerificationService } from '@alliance/employer/company/domain'
import { map } from 'rxjs/operators'

@Injectable()
export class EmployerChatPermittedGuard implements CanActivate {
  public constructor(private companyVerificationService: CompanyVerificationService, private router: Router, private authService: AuthService) {}

  public canActivate(): Observable<boolean | UrlTree> | boolean {
    if (!this.authService.isEmployer) {
      return true
    }

    return this.companyVerificationService.isVerified$.pipe(map((v): boolean | UrlTree => v || this.router.parseUrl('/')))
  }
}
