import { Inject, Injectable, Optional } from '@angular/core'
import { map, Observable, of } from 'rxjs'
import { catchError, retryWhen } from 'rxjs/operators'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { ApolloQueryResult } from '@apollo/client/core'
import { TranslationLanguageEnum } from '../../types-generated'
import { GetDistrictDictionaryGQL, GetDistrictDictionaryQuery } from './district-dictionary.generated'
import { DISTRICT_DICTIONARY_TOKEN } from '../../tokens/district-dictionary-token'
import { DistrictItemFragment } from '../city-dictionary/city-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class DistrictDictionaryService {
  public constructor(
    private getDistrictDictionaryGQL: GetDistrictDictionaryGQL,
    @Optional() @Inject(DISTRICT_DICTIONARY_TOKEN) private readonly districtDictionaryToken: ApolloQueryResult<GetDistrictDictionaryQuery> | null
  ) {}

  public getDistrictDictionaryList$(cityId: string, languages: TranslationLanguageEnum[]): Observable<DistrictItemFragment[]> {
    return (this.districtDictionaryToken ? of(this.districtDictionaryToken) : this.getDistrictDictionaryGQL.fetch({ cityId, languages }, { fetchPolicy: 'cache-first' })).pipe(
      map(data => (data?.data?.cityDistricts || []).reduce<DistrictItemFragment[]>((acc, district) => (district ? [...acc, district] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }
}
