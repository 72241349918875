import { TranslationLanguageEnum } from '../types-generated'
import { ExtendedDictionaryItem } from '../models/base-dictionary-models'
import { DistrictItemFragment, MicroDistrictItemFragment } from '../domain/city-dictionary/city-dictionary.generated'

export const localizedListItem = (item: DistrictItemFragment | MicroDistrictItemFragment, activeLanguage: TranslationLanguageEnum): ExtendedDictionaryItem => ({
  id: item.id,
  value: (item?.translations ?? []).find(translation => translation.language === activeLanguage)?.name ?? item.name,
  slug: item.slug,
  ru: item.translations.find(translation => translation.language === TranslationLanguageEnum.Russian)?.name ?? '',
  ua: item.translations.find(translation => translation.language === TranslationLanguageEnum.Ukrainian)?.name ?? '',
  en: item.translations.find(translation => translation.language === TranslationLanguageEnum.English)?.name ?? ''
})
