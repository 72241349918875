import { Provider, Type } from '@angular/core'
import { Route, Router, ROUTES } from '@angular/router'
import { getLanguageMatcher, LanguageRouter } from '../router'
import { TranslationService } from '../services'
import { LANGUAGE_GUARD, LANGUAGE_ROUTES, LanguageGuard, ROOT_COMPONENT } from '../tokens'
import { LANGUAGE_GUARD_PROVIDERS } from './language-guard.providers'

export interface provideLanguageRoutesParams {
  rootComponent: Type<unknown>
  rootRoute: Route[]
}

export const provideLanguageRoutes = ({ rootComponent, rootRoute }: provideLanguageRoutesParams): Provider[] => [
  LANGUAGE_GUARD_PROVIDERS,
  {
    provide: LANGUAGE_ROUTES,
    useFactory: (languageGuard: Type<LanguageGuard>, translationService: TranslationService): Route[] => [
      {
        matcher: getLanguageMatcher(translationService.getAvailableLanguages()),
        children: [{ path: '', canActivate: [languageGuard], children: rootRoute }]
      }
    ],
    deps: [LANGUAGE_GUARD, TranslationService]
  },
  {
    provide: ROUTES,
    useFactory: (routes: Route[]): Route[] => routes,
    deps: [LANGUAGE_ROUTES],
    multi: true
  },
  {
    provide: ROOT_COMPONENT,
    useValue: rootComponent
  },
  {
    provide: Router,
    useClass: LanguageRouter
  }
]
