import { DataLayerPushEvent } from '@alliance/shared/utils'

export const STATISTIC_DATE_CALENDAR_EVENT: DataLayerPushEvent = {
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: 'date_calendar',
  eventLabel: null,
  eventContent: null
}

export type StatisticDatePeriodEventType = 'all' | 'last_month' | 'last_week' | 'publish'

export const getStatisticDatePeriodEvent = (type: StatisticDatePeriodEventType): DataLayerPushEvent => ({
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: `date_period_${type}`,
  eventLabel: null,
  eventContent: null
})
