import { LanguageCodesEnum } from '@alliance/shared/models'
import { TranslationLanguageEnum } from '../types-generated'

export const transformLangValue = (lang?: LanguageCodesEnum): TranslationLanguageEnum => {
  switch (lang) {
    case LanguageCodesEnum.UK:
      return TranslationLanguageEnum.Ukrainian
    case LanguageCodesEnum.RU:
      return TranslationLanguageEnum.Russian
    default:
      return TranslationLanguageEnum.Ukrainian
  }
}
