import { BanReasonInfo } from './ban-reason-info'
import { PrivacySettingsInfo } from './privacy-settings-info'
export interface CvStateInfo {
  level?: CvStateInfo.LevelEnum
  anonymous?: boolean
  branchIds?: Array<number>
  companyIds?: Array<number>
  viewCount?: number
  banReason?: Array<BanReasonInfo>
  active?: boolean
  isDeleted?: boolean
  ukrainian?: boolean
  privacySettings?: PrivacySettingsInfo
  resumeId?: number
}

export namespace CvStateInfo {
  export type LevelEnum = 'ExceptEmployers' | 'Hide' | 'MegaEmployer' | 'None' | 'OnlyForVerifiedEmployer' | 'Public' | 'StrongDefinedEmployer'
  export const LevelEnum = {
    ExceptEmployers: 'ExceptEmployers' as LevelEnum,
    Hide: 'Hide' as LevelEnum,
    MegaEmployer: 'MegaEmployer' as LevelEnum,
    None: 'None' as LevelEnum,
    OnlyForVerifiedEmployer: 'OnlyForVerifiedEmployer' as LevelEnum,
    Public: 'Public' as LevelEnum,
    StrongDefinedEmployer: 'StrongDefinedEmployer' as LevelEnum
  }
}
