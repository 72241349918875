import { Environment } from '@alliance/shared/environment'
import { LANGUAGE } from '@alliance/shared/models'
import { inject, Provider } from '@angular/core'
import { TRANSLOCO_CONFIG, TranslocoConfig } from '@ngneat/transloco'
import { translocoDefaultConfig } from '../constants'
import { TranslationService } from '../services'

export const provideTranslocoConfig = (config?: TranslocoConfig): Provider => ({
  provide: TRANSLOCO_CONFIG,
  useFactory: (): TranslocoConfig => {
    const environment = inject(Environment)
    const defaultConfig = translocoDefaultConfig(environment)

    return {
      ...defaultConfig,
      ...config,
      missingHandler: {
        ...defaultConfig.missingHandler,
        ...config?.missingHandler
      }
    }
  }
})

export const LANGUAGE_PROVIDER: Provider = {
  provide: LANGUAGE,
  useExisting: TranslationService
}
