import { DataLayerPushEvent } from '@alliance/shared/utils'

export type StatisticChangeChartModeEventType = 'cumulative' | 'days'
export type StatisticShowChartEventType = 'show' | 'interaction' | 'actions' | 'applies'

export const getStatisticChangeChartModeEvent = (type: StatisticChangeChartModeEventType): DataLayerPushEvent => ({
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: `plot_${type}`,
  eventLabel: null,
  eventContent: null
})

export const getStatisticShowChartEvent = (type: StatisticShowChartEventType, show: boolean): DataLayerPushEvent => ({
  event: 'EMP_Statistic',
  eventCategory: 'EMP_Statistic',
  eventAction: `plot_${show ? 'on' : 'off'}_${type}`,
  eventLabel: null,
  eventContent: null
})
