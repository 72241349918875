export interface VacancyListModel {
  filters: { [key: string]: string }
  totalItems: number
  url: string
}

export enum AllowedIndexFiltersEnum {
  cityId = 'cityId',
  keywords = 'keywords',
  page = 'page',
  districtIds = 'districtIds',
  microDistrictIds = 'microDistrictIds',
  gender = 'gender',
  clusterKeywords = 'clusterKeywords'
}

// Тип для комбинации фильтров
export type FilterCombination<T extends AllowedIndexFiltersEnum[]> = [...T]

export type AllowedFilters = Partial<Record<AllowedIndexFiltersEnum, string>>

export const AllowedFilterCombinations: Array<FilterCombination<AllowedIndexFiltersEnum[]>> = [
  [AllowedIndexFiltersEnum.cityId],
  [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.keywords]
  // [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.districtIds],
  // [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.microDistrictIds],
  // [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.clusterKeywords, AllowedIndexFiltersEnum.keywords],
  // [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender],
  // [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.districtIds, AllowedIndexFiltersEnum.gender],
  // [AllowedIndexFiltersEnum.cityId, AllowedIndexFiltersEnum.gender, AllowedIndexFiltersEnum.microDistrictIds]
]

export type SEOObject = {
  h1: string
  title: string
  description: string
}

export interface VacListSeoParams {
  totalItems: number
  combinationKey: string
  filters: AllowedFilters | null
}
