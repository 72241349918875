import { SEOObject } from '../../openapi/model/vacancy-list.model'
import { RUA_DOMAIN } from '../constants'
import { PublishedVacanciesGenderEnum } from '@alliance/shared/domain-gql'

const genderTemplate = `{ gender, select, ${PublishedVacanciesGenderEnum.Male}{чоловіків} ${PublishedVacanciesGenderEnum.Female}{жінок} other{} }`

export const UA_SEO_MAPPING: Record<string, SEOObject> = {
  cityId: {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `▷ Робота в {cityId} | Пошук вакансій в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу в {cityId}? ✔ Робота в {cityId} - {totalItems} актуальних вакансій в {cityId} для вас! ✌ Знайди свою роботу в {cityId} на ${RUA_DOMAIN}`
  },
  'cityId+keywords': {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}, пошук {keywords, select, null{вакансій} other{вакансій {keywords}}} в {cityId} | ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} ✪ шукаєте {keywords, select, null{роботу} other{роботу {keywords}}} в {cityId}? ➤ {totalItems} актуальних {keywords, select, null{вакансій} other{вакансій {keywords}}} в {cityId}. ☛ Актуальні вакансії`
  },
  'cityId+districtIds': {
    h1: `Робота {districtIds} район в {cityId}`,
    title: `▷ Робота {districtIds} район в {cityId} | Пошук вакансій {districtIds} район в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {districtIds} район в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+microDistrictIds': {
    h1: `Работа мікрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Робота мікрорайон {microDistrictIds} в {cityId} | Пошук вакансій {microDistrictIds} мікрорайон в {cityId} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу мікрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+clusterKeywords+keywords': {
    h1: `Робота {keywords} {clusterKeywords} в {cityId}`,
    title: `▷ Робота {keywords} {clusterKeywords} в {cityId} | Пошук вакансій {keywords} {clusterKeywords} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу {keywords} {clusterKeywords} в {cityId} ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender': {
    h1: `Робота популярна у ${genderTemplate} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+districtIds+gender': {
    h1: `Робота популярна у ${genderTemplate} {districtIds} район в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} {districtIds} район в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} {districtIds} район в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  },
  'cityId+gender+microDistrictIds': {
    h1: `Робота популярна у ${genderTemplate} мікрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Робота популярна у ${genderTemplate} мікрорайон {microDistrictIds} в {cityId} | Пошук вакансій популярних у ${genderTemplate} - знайди свою роботу на ${RUA_DOMAIN}`,
    description: `Шукаєте роботу популярну у ${genderTemplate} мікрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальних вакансій для вас! ✌ Знайди свою роботу на ${RUA_DOMAIN}`
  }
}
