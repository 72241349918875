import { ModuleWithProviders, NgModule, Provider } from '@angular/core'
import { TranslocoConfig, TranslocoDirective, TranslocoModule, TranslocoPipe } from '@ngneat/transloco'
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat'
import { TranslatePipe } from './pipes'
import { LANGUAGE_PROVIDER, provideTranslocoConfig } from './providers'

@NgModule({
  imports: [TranslocoModule, TranslocoMessageFormatModule.forRoot({ locales: ['ru-RU', 'uk-UA'] })],
  exports: [TranslocoPipe, TranslocoDirective, TranslatePipe],
  declarations: [TranslatePipe]
})
export class SharedTranslationModule {
  public static forRoot(config?: TranslocoConfig, additionalProviders: Provider[] = []): ModuleWithProviders<SharedTranslationModule> {
    return {
      ngModule: SharedTranslationModule,
      providers: [provideTranslocoConfig(config), LANGUAGE_PROVIDER, additionalProviders]
    }
  }
}
