import { Inject, Injectable, Optional } from '@angular/core'
import { map, Observable, of } from 'rxjs'
import { catchError, retryWhen } from 'rxjs/operators'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { ApolloQueryResult } from '@apollo/client/core'
import { TranslationLanguageEnum } from '../../types-generated'
import { DictionaryCityItemFragment, GetDictionaryCitiesGQL, GetDictionaryCitiesQuery } from './city-dictionary.generated'
import { CITY_DICTIONARY_TOKEN } from '../../tokens/city-dictionary-token'

@Injectable({
  providedIn: 'root'
})
export class CityDictionaryService {
  public constructor(
    private getDictionaryCitiesGQL: GetDictionaryCitiesGQL,
    @Optional() @Inject(CITY_DICTIONARY_TOKEN) private readonly cityDictionaryToken: ApolloQueryResult<GetDictionaryCitiesQuery> | null
  ) {}

  public getCityDictionaryList$(languages: TranslationLanguageEnum[]): Observable<DictionaryCityItemFragment[]> {
    return (this.cityDictionaryToken ? of(this.cityDictionaryToken) : this.getDictionaryCitiesGQL.fetch({ languages }, { fetchPolicy: 'cache-first' })).pipe(
      map(data => (data?.data?.cities || []).reduce<DictionaryCityItemFragment[]>((acc, city) => (city ? [...acc, city] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }
}
