import { CompanyStateEnum } from '@alliance/shared/domain-gql'

import { BlockingReasonItemFragment } from '../services/company-verification'
import { CompanyVerificationStatus } from '../models'

const VERIFICATION_BY_COMPANY_STATE_MAP: Record<CompanyVerificationStatus, CompanyStateEnum[]> = {
  checked: [CompanyStateEnum.Mega],
  onInspection: [CompanyStateEnum.ExpectationOfActivation],
  deflected: [CompanyStateEnum.ExpectationOfActivation],
  registered: [CompanyStateEnum.New, CompanyStateEnum.ExpectationOfActivation, CompanyStateEnum.NotActivated, CompanyStateEnum.Undefined, CompanyStateEnum.Validated]
}

const VERIFICATION_BY_HAS_BLOCKING_REASONS_MAP: Record<CompanyVerificationStatus, boolean> = {
  checked: false,
  onInspection: false,
  deflected: true,
  registered: false
}

export const getVerificationStatusByCompanyData = (companyState: CompanyStateEnum, blockingReasons: BlockingReasonItemFragment[]): CompanyVerificationStatus | null => {
  const hasBlockingReasons = !!blockingReasons.length
  const isToVerification = true // mock
  const isVerifiedCAP = true // mock

  if (VERIFICATION_BY_COMPANY_STATE_MAP.checked.includes(companyState) && VERIFICATION_BY_HAS_BLOCKING_REASONS_MAP.checked === hasBlockingReasons && isVerifiedCAP) {
    return 'checked'
  }

  if (VERIFICATION_BY_COMPANY_STATE_MAP.onInspection.includes(companyState) && VERIFICATION_BY_HAS_BLOCKING_REASONS_MAP.onInspection === hasBlockingReasons && isToVerification) {
    return 'onInspection'
  }

  if (VERIFICATION_BY_COMPANY_STATE_MAP.deflected.includes(companyState) && VERIFICATION_BY_HAS_BLOCKING_REASONS_MAP.deflected === hasBlockingReasons) {
    return 'deflected'
  }

  if (VERIFICATION_BY_COMPANY_STATE_MAP.registered.includes(companyState) && VERIFICATION_BY_HAS_BLOCKING_REASONS_MAP.registered === hasBlockingReasons && !isToVerification) {
    return 'registered'
  }

  return null
}
