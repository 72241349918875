import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, retry, switchMap } from 'rxjs/operators'

import { AuthService } from '@alliance/shared/auth/api'
import { CompanyStateEnum } from '@alliance/shared/domain-gql'
import { RxStateService } from '@alliance/shared/models'
import { getNonNullableItems, retryWhenStrategy } from '@alliance/shared/utils'

import { CompanyDataFragment, GetCompanyDataGQL } from './company-verification.generated'
import { CompanyState } from '../../models/company-state.interface'
import { getVerificationStatusByCompanyData } from '../../constants/get-verification-status-by-company-data'
import { CompanyVerificationFeatureService } from './company-verification-feature.service'

@Injectable({ providedIn: 'root' })
export class CompanyVerificationService extends RxStateService<{
  companyData: CompanyDataFragment | null
  companyState: CompanyState
  isVerified: boolean
}> {
  public isVerified$: Observable<boolean> = this.select('isVerified')

  public constructor(private authService: AuthService, private getCompanyDataGQL: GetCompanyDataGQL, private readonly companyVerificationFeatureService: CompanyVerificationFeatureService) {
    super()

    this.initState({
      companyData: this.authService.token$.pipe(switchMap(token => (!!token && this.authService.isEmployer ? this.getCompanyData$() : of(null)))),
      companyState: this.companyState$,
      isVerified: this.select('companyData').pipe(map(d => !!d && (d.companyState === CompanyStateEnum.Validated || d.companyState === CompanyStateEnum.Mega)))
    })
  }

  private get companyState$(): Observable<CompanyState> {
    return this.select('companyData').pipe(
      filter(Boolean),
      map(companyData => {
        const state = companyData.companyState

        const blockingReasons = state === CompanyStateEnum.ExpectationOfActivation ? getNonNullableItems(companyData.blockingReasons?.reasons || []) : [] // is ExpectationOfActivation needed

        const isCompanyNotVerified = state === CompanyStateEnum.Validated && !(companyData.edrpou || companyData.edrpouFile || companyData.companyUrl)

        const isAwaitingVerification = state === CompanyStateEnum.Validated && !!(companyData.edrpou && companyData.edrpouFile)

        const verificationStatus = this.companyVerificationFeatureService.getVerificationStatus() ?? (state ? getVerificationStatusByCompanyData(state, blockingReasons) : null)

        return { state, blockingReasons, isCompanyNotVerified, isAwaitingVerification, verificationStatus }
      })
    )
  }

  public refetchCompanyData(): void {
    this.hold(this.getCompanyData$(), companyData => this.set({ companyData }))
  }

  private getCompanyData$(): Observable<CompanyDataFragment | null> {
    const id = this.authService.user?.NotebookId?.toString() ?? ''
    return this.getCompanyDataGQL.fetch({ id }, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => data?.company ?? null),
      retry({ delay: retryWhenStrategy() }),
      catchError(() => of(null))
    )
  }
}
