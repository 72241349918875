import { SEOObject } from '../../openapi/model/vacancy-list.model'
import { RUA_DOMAIN } from '../constants'
import { PublishedVacanciesGenderEnum } from '@alliance/shared/domain-gql'

const genderTemplate = `{ gender, select, ${PublishedVacanciesGenderEnum.Male}{мужчин} ${PublishedVacanciesGenderEnum.Female}{женщин} other{} }`

export const RU_SEO_MAPPING: Record<string, SEOObject> = {
  cityId: {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `▷ Работа в {cityId} | Поиск вакансий в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу в {cityId}? ✔ Работа в {cityId} - {totalItems} актуальных вакансий в {cityId} для вас! ✌ Найти свою работу в {cityId} на ${RUA_DOMAIN}`
  },
  'cityId+keywords': {
    h1: '{keywords, select, null{Робота} other{Робота {keywords}}} в {cityId}',
    title: `{keywords, select, null{Работа} other{Работа {keywords}}} в {cityId}, поиск {keywords, select, null{вакансий} other{вакансий {keywords}}} в {cityId} | ${RUA_DOMAIN}`,
    description: `${RUA_DOMAIN} ✪ поиск {keywords, select, null{работы} other{работы {keywords}}} в {cityId}. ➤ {totalItems} актуальных {keywords, select, null{вакансий} other{вакансий {keywords}}} в {cityId}. ☛ Лучшие вакансии`
  },
  'cityId+districtIds': {
    h1: `Работа {districtIds} район в {cityId}`,
    title: `▷ Работа {districtIds} район в {cityId} | Поиск вакансий {districtIds} район в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {districtIds} район в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+microDistrictIds': {
    h1: `Работа микрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Работа микрорайон {microDistrictIds} в {cityId} | Поиск вакансий микрорайон {microDistrictIds} в {cityId} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу микрорайон {microDistrictIds} в {cityId}? ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+clusterKeywords+keywords': {
    h1: `Работа {keywords} {clusterKeywords} в {cityId}`,
    title: `▷ Работа {keywords} {clusterKeywords} в {cityId} | Поиск вакансий {keywords} {clusterKeywords} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу {keywords} {clusterKeywords} в {cityId} ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender': {
    h1: `Работа популярная среди ${genderTemplate} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} в {cityId} ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+districtIds+gender': {
    h1: `Работа популярная среди ${genderTemplate} {districtIds} район в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} {districtIds} район в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} {districtIds} район в {cityId} ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  },
  'cityId+gender+microDistrictIds': {
    h1: `Работа популярная среди ${genderTemplate} микрорайон {microDistrictIds} в {cityId}`,
    title: `▷ Работа популярная среди ${genderTemplate} микрорайон {microDistrictIds} в {cityId} | Поиск вакансий популярных среди ${genderTemplate} - найди свою работу на ${RUA_DOMAIN}`,
    description: `Ищете работу популярную среди ${genderTemplate} микрорайон {microDistrictIds} в {cityId} ✔ {totalItems} актуальных вакансий для вас! ✌ Найти свою работу на ${RUA_DOMAIN}`
  }
}
