import { ExtendedDictionaryList } from '@alliance/shared/dictionary'
import { DictionaryItem } from '@alliance/jobseeker/domain'

export const transformDictionaryList = (list: ExtendedDictionaryList): DictionaryItem[] =>
  (list ?? []).map(item => ({
    id: +item.id,
    value: item.value,
    ru: item.ru,
    ua: item.ua,
    en: item.en
  }))
