import { LanguageCodesEnum } from '@alliance/shared/models'
import { UrlMatcher } from '@angular/router'
import { COUNTRY_CODES_MAP, LANGUAGE_PARAM_NAME } from '../constants'

export const getLanguageMatcher =
  (availableLanguages: LanguageCodesEnum[]): UrlMatcher =>
  segments => {
    if (availableLanguages.some(languageCode => COUNTRY_CODES_MAP[languageCode] === segments?.[0]?.path)) {
      return {
        consumed: [segments[0]],
        posParams: { [LANGUAGE_PARAM_NAME]: segments[0] }
      }
    }

    return {
      consumed: []
    }
  }
