import { Inject, Injectable, Optional } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { TranslationService } from '../services'
import { LanguageGuard, SAVED_LANGUAGE, SavedLanguage } from '../tokens'
import { getLanguageFromParamMap } from '../utils'

@Injectable()
export class ClientLanguageGuard implements LanguageGuard {
  public constructor(
    @Optional() @Inject(SAVED_LANGUAGE) private readonly savedLanguage: SavedLanguage | null,
    private readonly router: Router,
    private readonly translationService: TranslationService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    const defaultLanguage = this.translationService.getDefaultLang()
    const currentLanguage = this.translationService.getCurrentLang()
    const languageFromSaved = this.savedLanguage?.getSavedLanguage()
    const languageFromParam = getLanguageFromParamMap(route.paramMap)

    if (!languageFromSaved && languageFromParam && languageFromParam !== defaultLanguage) {
      this.translationService.setActiveLang(languageFromParam)

      return true
    }

    const isSameLanguageFromParamAsDefault = languageFromParam === defaultLanguage
    const isWrongLanguageFromParam = !!languageFromParam && languageFromParam !== currentLanguage
    const shouldLanguageInParam = !languageFromParam && currentLanguage !== defaultLanguage

    if (isSameLanguageFromParamAsDefault || isWrongLanguageFromParam || shouldLanguageInParam) {
      const [path] = state.url.split('?')
      const { queryParams, fragment } = state.root

      return this.router.createUrlTree([path], { queryParams, ...(fragment && { fragment }) })
    }

    return true
  }
}
