import { DetectPlatformService, detectRobot } from '@alliance/shared/utils'
import { Provider, Type } from '@angular/core'
import { NAVIGATOR } from '@ng-web-apis/common'
import { ClientLanguageGuard, SSRLanguageGuard } from '../guards'
import { LANGUAGE_GUARD, LanguageGuard } from '../tokens'

export const LANGUAGE_GUARD_PROVIDERS: Provider[] = [
  ClientLanguageGuard,
  SSRLanguageGuard,
  {
    provide: LANGUAGE_GUARD,
    useFactory: (navigator: Navigator, platform: DetectPlatformService): Type<LanguageGuard> => (platform.isServer || detectRobot(navigator?.userAgent || '') ? SSRLanguageGuard : ClientLanguageGuard),
    deps: [NAVIGATOR, DetectPlatformService]
  }
]
