import { Injectable, Inject, Optional } from '@angular/core'
import { LANGUAGE, LanguageService } from '@alliance/shared/models'
import { TranslationLanguageEnum } from '../../types-generated'
import { map, Observable } from 'rxjs'
import { DEFAULT_LANG_LIST } from '../../constants/default-lang-list'
import { transformLangValue } from '../../constants/transform-lang-value'
import { MicroDistrictDictionaryService } from '../../domain/microdistrict-dictionary/microdistrict-dictionary.service'
import { ExtendedDictionaryList } from '../../models/base-dictionary-models'
import { localizedListItem } from '../../constants/localized-list-item'

@Injectable({
  providedIn: 'root'
})
export class MicroDistrictDictionaryApiService {
  public constructor(private microDistrictDictionaryService: MicroDistrictDictionaryService, @Optional() @Inject(LANGUAGE) private readonly language: LanguageService | null) {}

  public getMicroDistrictListDictionary$(cityId: string, activeLang?: TranslationLanguageEnum | null): Observable<ExtendedDictionaryList> {
    const activeLanguage = activeLang ?? transformLangValue(this.language?.activeLanguage)
    return this.microDistrictDictionaryService.getMicroDistrictDictionaryList$(cityId, DEFAULT_LANG_LIST).pipe(map(list => list.map(item => localizedListItem(item, activeLanguage))))
  }
}
