import { Inject, Injectable, Optional } from '@angular/core'
import { map, Observable, of } from 'rxjs'
import { catchError, retryWhen } from 'rxjs/operators'
import { retryWhenStrategy } from '@alliance/shared/utils'
import { ApolloQueryResult } from '@apollo/client/core'
import { GetMicroDistrictDictionaryGQL, GetMicroDistrictDictionaryQuery } from './microdistrict-dictionary.generated'
import { MICRO_DISTRICT_DICTIONARY_TOKEN } from '../../tokens/microdistrict-dictionary-token'
import { TranslationLanguageEnum } from '../../types-generated'
import { MicroDistrictItemFragment } from '../city-dictionary/city-dictionary.generated'

@Injectable({
  providedIn: 'root'
})
export class MicroDistrictDictionaryService {
  public constructor(
    private getMicroDistrictDictionaryGQL: GetMicroDistrictDictionaryGQL,
    @Optional() @Inject(MICRO_DISTRICT_DICTIONARY_TOKEN) private readonly microDistrictDictionaryToken: ApolloQueryResult<GetMicroDistrictDictionaryQuery> | null
  ) {}

  public getMicroDistrictDictionaryList$(cityId: string, languages: TranslationLanguageEnum[]): Observable<MicroDistrictItemFragment[]> {
    return (this.microDistrictDictionaryToken ? of(this.microDistrictDictionaryToken) : this.getMicroDistrictDictionaryGQL.fetch({ cityId, languages }, { fetchPolicy: 'cache-first' })).pipe(
      map(({ data: { city } }) => (city?.microDistricts || []).reduce<MicroDistrictItemFragment[]>((acc, microDistrict) => (microDistrict ? [...acc, microDistrict] : acc), [])),
      retryWhen(retryWhenStrategy()),
      catchError(() => of([]))
    )
  }
}
