import { CountryCodesEnum } from '@alliance/shared/models'
import { Location } from '@angular/common'
import { Compiler, Inject, Injectable, Injector, Type } from '@angular/core'
import { ChildrenOutletContexts, NavigationExtras, Route, Router, UrlSerializer, UrlTree } from '@angular/router'
import { TranslationService } from '../services'
import { LANGUAGE_ROUTES, ROOT_COMPONENT } from '../tokens'

@Injectable()
export class LanguageRouter extends Router {
  public constructor(
    @Inject(ROOT_COMPONENT) rootComponent: Type<unknown>,
    @Inject(LANGUAGE_ROUTES) routes: Route[],
    urlSerializer: UrlSerializer,
    rootContexts: ChildrenOutletContexts,
    location: Location,
    injector: Injector,
    compiler: Compiler,
    private readonly translationService: TranslationService
  ) {
    super(rootComponent, urlSerializer, rootContexts, location, injector, compiler, routes)
  }

  public override createUrlTree(commands: string[], navigationExtras?: NavigationExtras): UrlTree {
    if (!commands || !commands.length || commands[0]?.[0] !== '/') {
      return super.createUrlTree(commands, navigationExtras)
    }

    const regexp = new RegExp(`^\\/+(?:${Object.values(CountryCodesEnum).join('|')})(?=$|[\\/?])`)

    commands[0] = `${this.translationService.getLangPath()}${commands[0].replace(regexp, '')}`

    return super.createUrlTree(commands, navigationExtras)
  }
}
