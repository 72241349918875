import { CityDataLocalized, DataLocalized, KeywordInfo, PopularTagInfo } from '@alliance/jobseeker/data-access'
import { Query } from '@alliance/shared/models'
import { TranslationService } from '@alliance/shared/translation'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import {
  DictionaryCityAndRegionsItem,
  DictionaryCityItemWithRegion,
  DictionaryCityListWithCountry,
  DictionaryCityListWithRegions,
  DictionaryItem,
  DictionaryList,
  DictionaryMap,
  DictionaryModel,
  DictionaryPopularTagsList,
  DictionaryProfessionList,
  DictionaryRubricList,
  DictionaryTransliterationKeywordToCyrillic,
  LangCode
} from './dictionary.model'
import { DictionaryStore } from './dictionary.store'
import { DictionaryClass } from './dictionary.class'
import { DictionaryMapHelper } from './dictionary-map-helper'

@Injectable({
  providedIn: 'root'
})
export class DictionaryQuery extends Query<DictionaryModel> {
  public constructor(protected override store: DictionaryStore, private translationService: TranslationService, private dictionaryMapHelper: DictionaryMapHelper) {
    super(store)
  }
  public selectRubricMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('rubricList', langCode)
  }

  public selectProfessionsList$(): Observable<DictionaryProfessionList> {
    return this.select$('professionsList').pipe(filter(arr => arr.length > 0))
  }

  public selectRubricList$(langCode: LangCode | null = null): Observable<DictionaryRubricList> {
    return this.arrRubricList$('rubricList', langCode)
  }

  public selectSubRubricMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('subRubricList', langCode)
  }

  public selectSubRubricList$(langCode: LangCode | null = null): Observable<DictionaryRubricList> {
    return this.arrRubricList$('subRubricList', langCode)
  }
  public selectCityMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('cityList', langCode)
  }

  public selectCityAndRegionMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('citiesAndRegions', langCode)
  }

  public selectCitiesAndRegions$(): Observable<DictionaryCityAndRegionsItem[]> {
    return this.arrCityAndRegionsList$('citiesAndRegions')
  }
  public selectCityLocativeMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.select$('cityList').pipe(
      map(list => this.mapCityLocative(list, langCode)),
      filter(hash => Object.keys(hash).length > 0)
    )
  }

  public selectCitiesAndRegionsLocativeMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.select$('citiesAndRegions').pipe(
      map(list => this.mapCityLocative(list, langCode)),
      filter(hash => Object.keys(hash).length > 0)
    )
  }

  public selectCityList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('cityList', langCode)
  }

  public selectLanguageMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('languageList', langCode)
  }

  public selectLanguageList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('languageList', langCode)
  }

  public selectLanguageSkillMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('languageSkillList', langCode)
  }

  public selectLanguageSkillList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('languageSkillList', langCode)
  }

  public selectScheduleMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('scheduleList', langCode)
  }

  public selectScheduleList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('scheduleList', langCode)
  }

  public selectBranchMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('branchList', langCode)
  }

  public selectBranchList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('branchList', langCode)
  }

  public selectExperienceMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('experienceList', langCode)
  }

  public selectExperienceList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('experienceList', langCode)
  }

  public selectCvdbExperienceMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('cvdbExperienceList', langCode)
  }

  public selectCvdbExperienceList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('cvdbExperienceList', langCode)
  }

  public selectEducationMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('educationList', langCode)
  }

  public selectEducationList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('educationList', langCode)
  }

  public selectFillingTypesMap$(langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.mapList$('fillingTypesList', langCode)
  }

  public selectFillingTypesList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('fillingTypesList', langCode)
  }

  public selectAdditionalList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('additionalList', langCode)
  }

  public selectSphereList$(langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.arrList$('sphereList', langCode)
  }

  public selectDistrictMap(districts: DictionaryItem[]): DictionaryMap {
    return districts.reduce((acc, item) => {
      acc[item.id] = item.value
      return acc
    }, new DictionaryClass())
  }

  public selectCityListWithRegions$(langCode: LangCode | null = null): Observable<DictionaryCityListWithRegions> {
    return this.select$('cityList').pipe(
      map(list => {
        const result: DictionaryCityListWithRegions = []
        for (const item of list) {
          result.push(this.mapCity(item, item[(langCode ?? this.translationService.getCurrentLangFix_DEPRECATED()) as keyof CityDataLocalized] as string, false))
          if (item.centerId === item.id) {
            const lang = langCode ? (langCode as string) : this.translationService.getCurrentLangFix_DEPRECATED()
            result.push(this.mapCity(item, (item?.regionName as Record<string, string>)?.[lang], true))
          }
        }
        return result
      }),
      filter(arr => arr.length > 0)
    )
  }

  public selectCityListWithCountry$(): Observable<DictionaryCityListWithCountry[]> {
    return this.select$('cityWithCountry').pipe(
      map(list => list.reduce<DictionaryCityListWithCountry[]>((acc, curr) => (curr.id ? [...acc, { id: curr.id, ru: curr.ru || '', ua: curr.ua || '', countryId: curr.countryId || 0 }] : acc), [])),
      filter(arr => arr.length > 0)
    )
  }

  public selectKeywordsList$(): Observable<string[]> {
    return this.select$('keywordsList').pipe(filter(arr => arr.length > 0))
  }

  public selectStatusApplicationExperience(): Observable<DataLocalized[]> {
    return this.select$('statusApplicationExperience').pipe(filter(arr => arr.length > 0))
  }

  public selectStatusApplicationSalary(): Observable<DataLocalized[]> {
    return this.select$('statusApplicationSalary').pipe(filter(arr => arr.length > 0))
  }

  public selectPopularKeywordsList$(): Observable<DictionaryPopularTagsList> {
    return this.select$('popularKeywordsList').pipe(
      filter(arr => arr.length > 0),
      map((list: PopularTagInfo[]) =>
        list
          .filter(keyword => !!(this.translationService.currentLangIsUkrainian() ? keyword?.ua : keyword?.ru))
          .map(({ id, ru, ua, count }) => ({
            id: id ?? 0,
            count: count ?? 0,
            value: (this.translationService.currentLangIsUkrainian() ? ua : ru) ?? ''
          }))
      )
    )
  }

  public selectKeywordsWithTransliteration(): Observable<KeywordInfo[]> {
    return this.select$('keywordsWithTransliterationList').pipe(filter(arr => arr.length > 0))
  }

  public selectTransliterationKeywordToCyrillic(): Observable<DictionaryTransliterationKeywordToCyrillic | null> {
    return this.select$('transliterationKeywordToCyrillic').pipe(filter(data => data !== null))
  }
  private mapList$<Key extends keyof DictionaryModel>(key: Key, langCode: LangCode | null = null): Observable<DictionaryMap> {
    return this.select$(key).pipe(
      map(list => {
        const result = new DictionaryClass()
        for (const item of list as DataLocalized[]) {
          const index = item.id as number
          const lang = langCode ? (langCode as string) : this.translationService.getCurrentLangFix_DEPRECATED()
          result[index] = (item as Record<string, string>)[lang]
        }
        return result
      }),
      filter(hash => Object.keys(hash).length > 0)
    )
  }
  private arrList$<Key extends keyof DictionaryModel>(key: Key, langCode: LangCode | null = null): Observable<DictionaryList> {
    return this.select$(key).pipe(
      map(list =>
        (list as DataLocalized[]).map(item => ({
          id: item?.id ?? 0,
          value: item[(langCode ?? this.translationService.getCurrentLangFix_DEPRECATED()) as keyof DataLocalized] as string,
          ru: item?.ru ?? '',
          ua: item?.ua ?? '',
          en: item?.en ?? ''
        }))
      ),
      filter(arr => arr.length > 0)
    )
  }

  private arrCityAndRegionsList$<Key extends keyof DictionaryModel>(key: Key, langCode: LangCode | null = null): Observable<DictionaryCityAndRegionsItem[]> {
    return this.dictionaryMapHelper.arrCityAndRegionsList$(key, langCode)
  }

  private arrRubricList$<Key extends keyof DictionaryModel>(key: Key, langCode: LangCode | null = null): Observable<DictionaryRubricList> {
    return this.dictionaryMapHelper.arrRubricList$(key, langCode).pipe()
  }
  private mapCity(item: CityDataLocalized, value: string, inside: boolean): DictionaryCityItemWithRegion {
    return this.dictionaryMapHelper.mapCity(item, value, inside)
  }

  private mapCityLocative(list: CityDataLocalized[], langCode: LangCode | null = null): DictionaryClass {
    const result = new DictionaryClass()
    for (const item of list) {
      const regionName =
        list.filter(city => (this.translationService.currentLangIsUkrainian() ? item?.locativeName?.ua === city?.locativeName?.ua : item?.locativeName?.ru === city?.locativeName?.ru)).length > 1 &&
        item.id !== 15 /* Nikolaev is exception */
          ? ` (${(this.translationService.currentLangIsUkrainian() ? item?.regionName?.ua : item?.regionName?.ru) ?? ''})`
          : ''
      const index = item.id as number
      const lang = langCode ? (langCode as string) : this.translationService.getCurrentLangFix_DEPRECATED()
      const cityName = item?.locativeName ? (item.locativeName as Record<string, string>)[lang] : ''
      result[index] = cityName + regionName
    }
    return result
  }
}
