import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { TranslationService } from '../services'
import { LanguageGuard } from '../tokens'
import { getLanguageFromParamMap } from '../utils'

@Injectable()
export class SSRLanguageGuard implements LanguageGuard {
  public constructor(private readonly translationService: TranslationService) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const defaultLanguage = this.translationService.getDefaultLang()
    const languageFromParam = getLanguageFromParamMap(route.paramMap)

    if (languageFromParam && languageFromParam !== defaultLanguage) {
      this.translationService.setActiveLang(languageFromParam)
    }

    return true
  }
}
