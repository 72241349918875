import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloLink } from '@apollo/client/core'
import { TranslationService } from '../services'

@Injectable()
export class GqlLanguageMiddlewareService {
  public constructor(private translationService: TranslationService) {}

  public getMiddleware(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      const operationHeaders: unknown = operation.getContext()?.['headers']
      const headers = operationHeaders instanceof HttpHeaders ? operationHeaders : new HttpHeaders()

      operation.setContext({
        headers: headers.set('Accept-Language', this.translationService.getCurrentLang())
      })

      return forward(operation) as ApolloLink
    })
  }
}
